
@font-face {
  font-family: "NotoSansKR";
  font-style: normal;
  font-weight: 100;
  src: url("../src/fonts/NotoSansKR-Thin.ttf") format("truetype");
}

@font-face {
  font-family: "NotoSansKR";
  font-style: normal;
  font-weight: 400;
  src: url("../src/fonts/NotoSansKR-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "NotoSansKR";
  font-style: normal;
  font-weight: 700;
  src: url("../src/fonts/NotoSansKR-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 100;
  src: url("../src/fonts/Roboto-Thin.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: url("../src/fonts/Roboto-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  src: url("../src/fonts/Roboto-Bold.ttf") format("truetype");
}

* {
  margin: 0; padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: 'Roboto';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

li {
  list-style: none;
}

button {
  border: none;
  background: transparent;
  cursor: pointer;
}

a {
  text-decoration: none;
  color: #fff;
  cursor: pointer;
}

input, textarea {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
}

@media(max-width:1040px){
  .swiper-pagination{
    display: none;
  }
}

.swiper-pagination-bullet {
  background-color: #C4FF69;
  width: 10px;
  height: 10px;
  position: relative;
  margin: 20px !important;
  opacity: 1 !important;
}

.swiper-pagination-vertical.swiper-pagination-bullets{
  right: 5%;
}

.swiper-pagination-bullet-active {
  background: linear-gradient(141.84deg, #16BFB6 19.4%, #A6FF1B 82.8%);
  box-shadow: 0 0 0 7px transparent; 
}

.swiper-pagination-bullet-active::after {
  content: '';
  position: absolute;
  top: -7px;
  left: -7px;
  width: calc(100% + 14px); 
  height: calc(100% + 14px); 
  border: 2px solid #2AE4A1;
  border-radius: 50%; 
  box-sizing: border-box;
}